<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Edit Data Page">
      <form @submit.prevent="submitForm()">
        <div class="mt-2">
          <b-row>
            <!-- Nama Lengkap -->
            <b-col md="6" class="mb-1">
              <b-form-group label="Nama Page" :invalid-feedback="formErrors.name ? formErrors.name[0] : ''"
                :state="formErrors.name ? false : undefined">
                <b-form-input v-model="formData.name" placeholder="Masukkan Nama Page"
                  :state="formErrors.name ? false : undefined" />
              </b-form-group>
            </b-col>

            <b-col md="6" class="mb-1">
              <b-form-group label="Path / Slug" :invalid-feedback="formErrors.path ? formErrors.path[0] : ''"
                :state="formErrors.path ? false : undefined">
                <b-form-input v-model="formData.path" placeholder="Path / Slug"
                  :state="formErrors.path ? false : undefined" />
              </b-form-group>
            </b-col>

          </b-row>

          <div>
            <b-button variant="primary" class="float-right" type="submit">
              <div class="d-flex align-items-center">
                <b-spinner v-show="isLoading" class="mr-1" variant="light" small />
                Simpan
              </div>
            </b-button>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import { reqGetPage, reqUpdatePage } from '@/api/admin/page'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormText,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: '',
      avatar: '',

      formErrors: {},

      religion: ['Islam', 'Kristen', 'Katolik', 'Hindu', 'Budha', 'Konghucu'],

      formData: {
        name: '',
        path: ''
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.loadPage()
  },
  watch: {
    'formData.name': {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.formData.path = newValue
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
        }
      },
      deep: true
    }
  },
  methods: {
    avatarText,
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.avatar = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true

      return isValid
    },
    async loadPage() {
      await reqGetPage(this.$route.params.id)
        .then(response => {
          this.formData.name = response.data.data.name
          this.formData.path = response.data.data.path
        })
        .catch(error => {
          this.showToast(
            'danger',
            'AlertTriangleIcon',
            'Gagal',
            error.response.data.message || 'Data tidak ditemukan',
          )
        })
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        const data = new FormData()
        for (let i in this.formData) {
          if (this.formData[i]) {
            data.append(i, this.formData[i])
          }
        }

        await reqUpdatePage(this.$route.params.id, data)
          .then(response => {
            this.isLoading = false
            this.formErrors = {}

            this.showToast(
              'success',
              'Check',
              'Berhasil',
              response.data.message || 'User Berhasil Disimpan',
            )

            this.loadPage()
          })
          .catch(error => {
            this.isLoading = false
            this.formErrors = error.response.data.errors

            this.showToast(
              'danger',
              'AlertTriangleIcon',
              'Gagal',
              error.response.data.message || 'User Gagal Disimpan',
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
